import { HashLink } from 'react-router-hash-link';

const navigation = {
  main: [
    { name: 'Home', href: '/#' },
    { name: 'Why GiveMasjid?', href: '/#why' },
    { name: 'How We Help', href: '/#how' },
    { name: 'Impact', href: '/#impact' },
    { name: 'Features', href: '/#features' },
    { name: 'Testimonials', href: '/#testimonials' },
    { name: 'Contact', href: '/#contact' },
    // { name: 'Features', href: '/features' },
    // { name: 'Articles', href: 'https://articles.givemasjid.com' },
    // { name: 'FAQ', href: '/faq' },
    // { name: 'Reach Us', href: '/#reach-us' },
    { name: 'Register', href: '/register/#' },
    { name: 'Request a Trial', href: '/request-trial/#' },

  ]
}
export default function Footer() {
  return (
    <footer className="bg-white">
      <div className="max-w-7xl mx-auto py-6 sm:py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
        <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
          {navigation.main.map((item) => (
            <div key={item.name} className="px-2 sm:px-5 sm:py-2">
              <HashLink smooth to={item.href} className="text-xs sm:text-base text-gray-500 hover:text-gray-900">
                {item.name}
              </HashLink>
            </div>
          ))}
            <div className="px-2 sm:px-5 sm:py-2"> 
              <a href="https://status.givemasjid.com/" className="text-xs sm:text-base text-gray-500 hover:text-gray-900">Platform Status</a>
            </div>
        </nav>
        <p className="text-xs mt-3 text-center sm:text-base text-gray-400">&copy; 2024 GiveMasjid - <a href="https://sadaqahqr-web.s3.amazonaws.com/GiveMasjid_Terms_And_Conditions.pdf"> Terms and Conditions</a> - <a href="https://sadaqahqr-web.s3.amazonaws.com/GiveMasjid_Privacy_Policy.pdf"> Privacy Policy </a></p>
      </div>
    </footer>
  )
}
