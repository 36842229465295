import {
    CreditCardIcon,
  } from '@heroicons/react/outline'

  import YouTube from "./Youtube.jsx"
 
  
//   const features = [
//     {
//       name: 'Push to deploy.',
//       description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit aute id magna.',
//       icon: CreditCardIcon,
//     },
//     {
//       name: 'SSL certificates.',
//       description: 'Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.',
//       icon: CreditCardIcon,
//     },
//     {
//       name: 'Simple queues.',
//       description: 'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus.',
//       icon: CreditCardIcon,
//     },
//     {
//       name: 'Advanced security.',
//       description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit aute id magna.',
//       icon: CreditCardIcon,
//     },
//     {
//       name: 'Powerful API.',
//       description: 'Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.',
//       icon: CreditCardIcon,
//     },
//     {
//       name: 'Database backups.',
//       description: 'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. ',
//       icon: CreditCardIcon,
//     },
//   ]
  
  export default function Video() {
    return (
      <div className="bg-gray-900 py-14 sm:py-16" id="why">
        <div className="mx-auto max-w-4xl px-6 lg:px-4">
          <div className="mx-auto sm:text-center">
            {/* <h2 className="text-base font-semibold leading-7 text-indigo-400">Video</h2> */}
            <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl"><span className="text-emerald-500">Why </span> GiveMasjid?</p>
            <p className="mt-6 text-lg sm:text-xl leading-8 sm:leading-8 text-gray-300">
              Hear from our founder Ahmed on why we started GiveMasjid and why it might be the right solution for your masjid's fundraising needs. 
            </p>
          </div>
        </div>
        <div className="relative pt-6 flex justify-center"> {/* Updated line */}
            <div className="mx-auto px-6 lg:px-8 w-full lg:w-4/5">
            <div className="aspect-w-16 aspect-h-9">
            <iframe
              src="https://share.descript.com/embed/Bg8DEUz6eZP"
              frameBorder="0"
              allowFullScreen
              className="w-full h-full"
            />
          </div>                
            </div>  
      </div>
      </div>
    )
  }
  