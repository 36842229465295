import React, { useState, useEffect } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { createClient } from '@sanity/client';

// Initialize the Sanity client
const client = createClient({
  projectId: 'kmqnqcm9',
  dataset: 'production',
  useCdn: true, // `false` if you want to ensure fresh data
  apiVersion: '2025-01-23', // use current date (YYYY-MM-DD) to target the latest API version
});

// const posts = [
//   {
//     id: 1,
//     title: 'Boost your conversion rate',
//     href: '#',
//     description:
//       'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
//     imageUrl:
//       'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
//     date: 'Mar 16, 2020',
//     datetime: '2020-03-16',
//     category: { title: 'Marketing', href: '#' },
//     author: {
//       name: 'Michael Foster',
//       role: 'Co-Founder / CTO',
//       href: '#',
//       imageUrl:
//         'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
//     },
//   },
//   {
//     id: 2,
//     title: 'Maximizing your social media impact',
//     href: '#',
//     description:
//       'Learn how to effectively use social media platforms to grow your business and engage with your audience.',
//     imageUrl:
//       'https://images.unsplash.com/photo-1552664730-d307ca884978?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3540&q=80',
//     date: 'Mar 23, 2020',
//     datetime: '2020-03-23',
//     category: { title: 'Social Media', href: '#' },
//     author: {
//       name: 'Sarah Johnson',
//       role: 'Marketing Director',
//       href: '#',
//       imageUrl:
//         'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
//     },
//   },
//   {
//     id: 3,
//     title: 'Effective email marketing strategies',
//     href: '#',
//     description:
//       'Discover proven techniques to create engaging email campaigns that convert readers into customers.',
//     imageUrl:
//       'https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3540&q=80',
//     date: 'Mar 30, 2020',
//     datetime: '2020-03-30',
//     category: { title: 'Email Marketing', href: '#' },
//     author: {
//       name: 'David Thompson',
//       role: 'Email Marketing Specialist',
//       href: '#',
//       imageUrl:
//         'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
//     },
//   },
//   {
//     id: 4,
//     title: 'The power of content marketing',
//     href: '#',
//     description:
//       'Understand how valuable content can drive traffic, generate leads, and establish your brand authority.',
//     imageUrl:
//       'https://images.unsplash.com/photo-1499750310107-5fef28a66643?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3540&q=80',
//     date: 'Apr 6, 2020',
//     datetime: '2020-04-06',
//     category: { title: 'Content Marketing', href: '#' },
//     author: {
//       name: 'Emily Davis',
//       role: 'Content Strategist',
//       href: '#',
//       imageUrl:
//         'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
//     },
//   },
//   {
//     id: 5,
//     title: 'The power of content marketing',
//     href: '#',
//     description:
//       'Understand how valuable content can drive traffic, generate leads, and establish your brand authority.',
//     imageUrl:
//       'https://images.unsplash.com/photo-1499750310107-5fef28a66643?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3540&q=80',
//     date: 'Apr 6, 2020',
//     datetime: '2020-04-06',
//     category: { title: 'Content Marketing', href: '#' },
//     author: {
//       name: 'Emily Davis',
//       role: 'Content Strategist',
//       href: '#',
//       imageUrl:
//         'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
//     },
//   }
// ]

export default function FeaturedBlog() {
    const [posts, setPosts] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);  // Add this line

    const postsPerPage = 3;

    useEffect(() => {
      const fetchPosts = async () => {
        const query = `*[_type == "post" && status == "published"] | order(publishedAt desc) {
          _id,
          title,
          slug,
          publishedAt,
          excerpt,
          "category": category->title,
          "author": author->{name, "image": image.asset->url},
          "previewImage": previewImage.asset->url
        }`;
  
        try {
          const fetchedPosts = await client.fetch(query);
          setPosts(fetchedPosts);
          setTotalPages(Math.ceil(fetchedPosts.length / postsPerPage));
        } catch (error) {
          console.error("Error fetching posts:", error);
        }
      };
  
      fetchPosts();
    }, []);

    const nextPosts = () => {
      setCurrentPage(prev => Math.min(prev + 1, totalPages - 1));
    };

    const prevPosts = () => {
      setCurrentPage(prev => Math.max(prev - 1, 0));
    };

    const visiblePosts = posts.slice(
      currentPage * postsPerPage,
      currentPage * postsPerPage + postsPerPage
    );
  
    return (
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-balance text-3xl font-extrabold tracking-tight text-gray-900 sm:text-3xl relative inline-block pb-4">
              GiveMasjid Blog Posts
              <div className="absolute bottom-0 left-0 w-full h-1 bg-indigo-500"></div>
            </h2>
            <p className="mt-2 text-lg/8 text-gray-600">Learn how to grow your business with our expert advice.</p>
          </div>
          <div className="relative mx-auto mt-16 max-w-7xl overflow-hidden">

            {/* Contents */}
            <div className="grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {visiblePosts.map((post) => (
            <article key={post._id} className="flex flex-col items-start justify-between">
              <div className="relative w-full group">
              {post.previewImage && (
                <img
                  alt={post.title}
                  src={post.previewImage}
                  className="aspect-video w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                />
              )}
                <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
              </div>
              <div className="max-w-xl">
                <div className="mt-8 flex items-center gap-x-4 text-xs">
                  <time dateTime={post.publishedAt} className="text-gray-500">
                    {new Date(post.publishedAt).toLocaleDateString()}
                  </time>
                  {post.category && (
                        <span className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-indigo-50 hover:text-indigo-700 transition-colors duration-200">
                          {post.category}
                        </span>
                  )}
                </div>
                <div className="group relative">
                  <h3 className="mt-3 text-lg/6 font-semibold text-gray-900 group-hover:text-gray-600">
                    <a href={`/post/${post.slug.current}`}>
                      <span className="absolute inset-0" />
                      {post.title}
                    </a>
                  </h3>
                  {post.excerpt && <p className="mt-5 line-clamp-3 text-sm/6 text-gray-600">{post.excerpt}</p>}

                </div>
                {post.author && (
  <div className="relative mt-8 flex items-center gap-x-4">
    {post.author.image && (
      <img alt={post.author.name} src={post.author.image} className="size-10 rounded-full bg-gray-100" />
    )}
    <div className="text-sm/6">
      <p className="font-semibold text-gray-900">
        {post.author.name}
      </p>
    </div>
  </div>
)}
              </div>
            </article>
          ))}
        </div>
            <div className="mt-12 flex items-center justify-center gap-4">
                <button
                onClick={prevPosts}
                disabled={currentPage === 0}
                className="bg-white rounded-full p-2 shadow-md hover:bg-indigo-50 hover:text-indigo-600 transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <ChevronLeftIcon className="h-6 w-6" />
              </button>
              <span className="text-gray-600">
                Page {currentPage + 1} of {totalPages}
              </span>
              <button
                onClick={nextPosts}
                disabled={currentPage === totalPages - 1}
                className="bg-white rounded-full p-2 shadow-md hover:bg-indigo-50 hover:text-indigo-600 transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <ChevronRightIcon className="h-6 w-6" />
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
